(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/helpers/assets/javascripts/get-min-required-shares.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/helpers/assets/javascripts/get-min-required-shares.js');
"use strict";


const getMinRequiredShares = activatedDraw => {
  const apiRequiredShares = activatedDraw && activatedDraw.numSharesRequired;
  const realRequiredShares = apiRequiredShares < 2 ? 2 : apiRequiredShares;
  return realRequiredShares;
};
setGlobal('svs.components.marketplaceRetailAdmin.helpers.getMinRequiredShares', getMinRequiredShares);

 })(window);