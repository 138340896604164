(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/helpers/assets/javascripts/is-authorized-for-container.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/helpers/assets/javascripts/is-authorized-for-container.js');

'use strict';

const {
  roles
} = svs.components.marketplaceRetailAdminData.auth;

const isAuthorizedForContainer = _ref => {
  let {
    containerOwnerId,
    editorId,
    editorRole
  } = _ref;
  return roles.isAuthorized(editorRole, roles.PERMISSIONS.CREATE_GAME, {
    isGameTemplateOwner: containerOwnerId === editorId
  });
};
setGlobal('svs.components.marketplaceRetailAdmin.helpers.isAuthorizedForContainer', isAuthorizedForContainer);

 })(window);