(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin/helpers/assets/javascripts/is-container-playable.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin/helpers/assets/javascripts/is-container-playable.js');
'use strict';

const {
  selectors
} = svs.components.marketplaceData;
const {
  isAuthorizedForContainer
} = svs.components.marketplaceRetailAdmin.helpers;

function isContainerPlayable(state, container) {
  const openDraws = selectors.draws.selectOpenDrawsByProduct(state, container.productName);
  const activeDraws = selectors.games.getGamesByContainerId(state, state.EditorSession.groupId, container.id).map(activatedDraw => "".concat(activatedDraw.productId, "_").concat(activatedDraw.drawNumber));
  const hasDrawsToPlay = (openDraws || []).filter(draw => activeDraws.indexOf("".concat(draw.productId, "_").concat(draw.drawNumber)) === -1);
  const isAuthorized = isAuthorizedForContainer({
    containerOwnerId: container.ownerId,
    editorId: state.EditorSession.editorId,
    editorRole: state.EditorSession.role
  });
  return isAuthorized && hasDrawsToPlay.length > 0;
}
setGlobal('svs.components.marketplaceRetailAdmin.helpers.isContainerPlayable', isContainerPlayable);

 })(window);